'use client'

import * as React from 'react'
import {
  LoginMethod,
  type UserByUsernameQuery,
} from '@/api/graphql/generated/hooks'
import { UpdatePasswordForm } from './update-password/form'
import type { LoginProvider } from './login/form'
import { LoginForm } from './login/form'
import { LoginVerification } from './otp'
import { LoginLayoutDescription } from './login-layout-template'
import { type LoginUserReturn } from './login/actions'

export function Login(props: {
  isViewpointRedirectEnabled: boolean
  providers: LoginProvider[]
  updatePasswordToken?: string
  updatePasswordTokenPayload?: {
    isAuthenticated: boolean
    tokenUser: UserByUsernameQuery['userByUsername'] | undefined
    error: unknown
  }
}): React.ReactElement | null {
  const {
    isViewpointRedirectEnabled,
    providers,
    updatePasswordToken: token,
    updatePasswordTokenPayload,
  } = props

  const { isAuthenticated, tokenUser } = updatePasswordTokenPayload ?? {}
  // const searchParams = useSearchParams()

  // const callbackUrl = searchParams?.get('callbackUrl')

  const [shownComponent, setShownComponent] = React.useState<
    'login' | 'password' | 'mfa'
  >(() => {
    if (token) {
      return 'password'
    }
    return 'login'
  })

  const [email, setEmail] = React.useState<string>()
  const [username, setUsername] = React.useState<string>()
  const [password, setPassword] = React.useState<string>()
  const [loginMethod, setLoginMethod] = React.useState<LoginMethod>()

  const [updatePasswordToken, setUpdatePasswordToken] = React.useState<
    string | undefined
  >(token)
  const [loginResponse, setLoginResponse] = React.useState<
    LoginUserReturn | undefined
  >()

  React.useEffect(() => {
    if (!loginResponse) {
      return
    }

    setShownComponent(loginResponse.shownComponent)

    if (loginResponse.shownComponent === 'password') {
      setEmail(loginResponse.email)
      setUsername(loginResponse.username)
      setLoginMethod(loginResponse.loginMethod)
      setUpdatePasswordToken(loginResponse.updatePasswordToken)
    }
    if (loginResponse.shownComponent === 'mfa') {
      setEmail(loginResponse.email)
      setUsername(loginResponse.username)
      setLoginMethod(loginResponse.loginMethod)
    }
  }, [loginResponse, token])

  React.useEffect(() => {
    if (token) {
      setUpdatePasswordToken(token)
      setEmail(tokenUser?.email)
      setUsername(tokenUser?.username)
      setLoginMethod(tokenUser?.loginMethod)
    }
  }, [token, tokenUser])

  const MfaComponent = React.useMemo(() => {
    if (shownComponent !== 'mfa') {
      return null
    }

    if (!(password && username && email)) {
      return null
    }

    // if (pathname) {
    //   router.replace(pathname)
    // }

    return (
      <LoginVerification
        email={email}
        isLdap={loginMethod === LoginMethod.Network}
        isViewpointRedirectEnabled={props.isViewpointRedirectEnabled}
        password={password}
        username={username}
      />
    )
  }, [
    email,
    loginMethod,
    password,
    props.isViewpointRedirectEnabled,
    shownComponent,
    username,
  ])

  const UpdatePasswordComponent = React.useMemo(() => {
    if (!(username && updatePasswordToken)) {
      return null
    }

    if (shownComponent !== 'password') {
      return null
    }

    return (
      <>
        <LoginLayoutDescription
          description="You need to update your password before signing in."
          title="Update your password"
        />
        <UpdatePasswordForm
          isAuthenticated={isAuthenticated}
          setLoginResponse={setLoginResponse}
          setPassword={setPassword}
          username={username}
          token={updatePasswordToken}
        />
      </>
    )
  }, [isAuthenticated, shownComponent, updatePasswordToken, username])

  const LoginComponent = React.useMemo(() => {
    if (shownComponent !== 'login') {
      return null
    }

    return (
      <LoginForm
        isViewpointRedirectEnabled={isViewpointRedirectEnabled}
        loginError={
          loginResponse?.shownComponent === 'login'
            ? loginResponse.loginError
            : undefined
        }
        message={
          loginResponse?.shownComponent === 'login'
            ? loginResponse.message
            : undefined
        }
        providers={providers}
        setLoginResponse={setLoginResponse}
        setPassword={setPassword}
      />
    )
  }, [isViewpointRedirectEnabled, loginResponse, providers, shownComponent])

  const returnComponent = React.useMemo(() => {
    const component = {
      login: LoginComponent,
      mfa: MfaComponent,
      password: UpdatePasswordComponent,
    }[shownComponent]

    return component
  }, [LoginComponent, MfaComponent, UpdatePasswordComponent, shownComponent])

  return returnComponent
}
