import { z } from 'zod'

export const loginFormSchema = z.object({
  username: z
    .string()
    .min(1, { message: 'This field cannot be empty.' })
    .max(200, { message: 'Username name must be 200 characters or less.' }),
  password: z.string().min(1, { message: 'This field cannot be empty.' }),
  isPasswordHashed: z.boolean().optional(),
})
