'use client'

import Link from 'next/link'
import * as React from 'react'
// eslint-disable-next-line import/named -- This is fine. For some reason, eslint is not recognizing the named import because it's technically part of the react canary release.
import { useFormState, useFormStatus } from 'react-dom'
import type * as z from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from '@viewpoint/ui'
import { usePathname, useRouter } from 'next/navigation'
import { type LoginUserReturn } from '@/app/login/components/login/actions'
import type { UserFieldsFragment } from '@/api/graphql/generated/hooks'
import { updateUserPassword } from './action'
import { updateUserPasswordFormSchema } from './schema'
import { UpdateErrorTimeout } from './update-error-timeout'

function SubmitButton({ isValid }: { isValid?: boolean }): React.ReactElement {
  const { pending } = useFormStatus()

  const buttonState = React.useMemo(() => {
    if (!isValid) {
      return 'disabled'
    }
    if (pending) {
      return 'loading'
    }
    return 'enabled'
  }, [isValid, pending])

  return (
    <Button fill size="lg" state={buttonState} type="submit" variant="primary">
      Update password
    </Button>
  )
}

export function UpdatePasswordForm({
  isAuthenticated,
  username,
  token,
  setLoginResponse,
  setPassword,
}: {
  isAuthenticated?: boolean
  onAction?: (newPassword: string) => void
  username: UserFieldsFragment['username']
  token: string
  setLoginResponse?: React.Dispatch<
    React.SetStateAction<LoginUserReturn | undefined>
  >
  setPassword?: React.Dispatch<React.SetStateAction<string | undefined>>
}): JSX.Element {
  const router = useRouter()
  const pathname = usePathname()

  const form = useForm<z.infer<typeof updateUserPasswordFormSchema>>({
    resolver: zodResolver(updateUserPasswordFormSchema),
    defaultValues: {},
    mode: 'onChange',
  })

  const [state, formAction] = useFormState(updateUserPassword, {
    message: '',
    shownComponent: 'password',
    username,
    updatePasswordToken: token,
  } satisfies LoginUserReturn)

  React.useEffect(() => {
    if (pathname && state.shownComponent !== 'password') {
      router.replace(pathname)
    }
    setLoginResponse?.(state)
  }, [pathname, router, setLoginResponse, setPassword, state])

  if (!isAuthenticated) {
    return <UpdateErrorTimeout />
  }

  const PasswordForm = (
    <Form {...form}>
      <form
        action={(formData) => {
          const password = form.getValues('newPassword')
          setPassword?.(password)

          formAction(formData)
        }}
        className="w-full space-y-3"
        id="login-form"
      >
        <FormField
          control={form.control}
          name="newPassword"
          render={({ field }) => (
            <FormItem className="w-full space-y-0.5">
              <FormControl>
                <Input
                  autoComplete="current-password"
                  className="w-full"
                  inputSize="lg"
                  placeholder="New Password"
                  type="password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmNewPassword"
          render={({ field }) => (
            <FormItem className="w-full space-y-0.5">
              <FormControl>
                <Input
                  autoComplete="new-password"
                  className="w-full"
                  inputSize="lg"
                  placeholder="Confirm Password"
                  type="password"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="mb-8 mt-4 flex items-center justify-center gap-x-1 text-sm text-gray-500 sm:justify-start">
          <span>Never Mind!</span>
          <Link
            className="focus-outline rounded-sm py-0 font-medium text-gray-600 underline ring-blue-600 hover:text-blue-700 focus:text-blue-700 dark:text-gray-400 dark:hover:text-blue-400 dark:focus:text-blue-400"
            href="/login"
          >
            Back to login
          </Link>
        </div>
        <div className="pt-4">
          <SubmitButton isValid={form.formState.isValid} />
        </div>
        <p aria-live="polite" className="sr-only" role="status">
          {state.message}
        </p>
      </form>
    </Form>
  )

  return <>{PasswordForm}</>
}
