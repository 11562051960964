'use client'

import * as React from 'react'
// eslint-disable-next-line import/named -- This is fine. For some reason, eslint is not recognizing the named import because it's technically part of the react canary release.
import { useFormState as useActionState, useFormStatus } from 'react-dom'
import Link from 'next/link'
import { useForm } from 'react-hook-form'
import type * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from '@viewpoint/ui'
import { AlertCircleIcon } from 'lucide-react'
import { GoogleIcon } from '@/components/icons/google-icon'
import { LoginLayoutDescription } from '../login-layout-template'
import { loginUser, type LoginUserReturn } from './actions'
import { loginFormSchema } from './schema'

export type LoginProvider =
  | {
      name: 'saml'
      url: string
    }
  | { name: 'google'; url: string }

export function LoginProviderButtons(props: {
  providers?: LoginProvider[]
}): React.ReactElement | null {
  const { providers = [] } = props

  const samlSsoUrl = providers.find((provider) => provider.name === 'saml')?.url
  const googleUrl = providers.find(
    (provider) => provider.name === 'google',
  )?.url

  const SamlSSOButton = (
    <Button className="z-10" fill size="lg" variant="outline">
      <a href={samlSsoUrl}>Sign in With SSO</a>
    </Button>
  )
  const GoogleButton = (
    <Button
      className="z-10"
      fill
      leftIcon={<GoogleIcon />}
      size="lg"
      variant="outline"
    >
      <a href={googleUrl}>Sign in with Google</a>
    </Button>
  )

  if (providers.length === 0) {
    return null
  }

  return (
    <div className="relative mb-8 flex w-full flex-col gap-1.5 after:absolute after:bottom-[0.65rem] after:left-0 after:right-0 after:top-0 after:mx-auto after:my-0 after:w-full after:border-b-2 after:border-gray-950/5 dark:after:border-white/5">
      {providers.find((provider) => provider.name === 'google')
        ? GoogleButton
        : null}
      {providers.find((provider) => provider.name === 'saml')
        ? SamlSSOButton
        : null}
      <span className="z-10 mx-auto mt-6 bg-white px-2 text-gray-500 dark:bg-gray-900">
        or
      </span>
    </div>
  )
}

function SubmitButton(props: { disabled?: boolean }): React.ReactElement {
  const { pending } = useFormStatus()

  const formState = React.useMemo(() => {
    if (props.disabled) {
      return 'disabled'
    }
    return pending ? 'loading' : 'enabled'
  }, [pending, props.disabled])

  return (
    <div className="pt-4">
      <Button fill size="lg" state={formState} type="submit" variant="primary">
        Sign In
      </Button>
    </div>
  )
}

function LoginForm(props: {
  loginError?: {
    title?: string
    description?: string
  }
  message?: string
  setPassword?: React.Dispatch<React.SetStateAction<string | undefined>>
  isViewpointRedirectEnabled: boolean
  providers?: LoginProvider[]
  setLoginResponse?: React.Dispatch<
    React.SetStateAction<LoginUserReturn | undefined>
  >
}): React.JSX.Element | null {
  const [title] = React.useState('Welcome back')
  const [description] = React.useState('Sign into your account')

  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'onBlur',
  })

  const [state, formAction] = useActionState(loginUser, {
    message: '',
    shownComponent: 'login',
  } satisfies LoginUserReturn)

  const loginError = React.useMemo(() => {
    if (state.shownComponent === 'login') {
      return state.loginError
    }
  }, [state])

  const FormError = React.useMemo(
    () => (
      <Alert className="mb-4" variant="destructive">
        <AlertCircleIcon className="h-4 w-4" />
        {loginError?.title ? <AlertTitle>{loginError.title}</AlertTitle> : null}
        {loginError?.description ? (
          <AlertDescription>{loginError.description}</AlertDescription>
        ) : null}
      </Alert>
    ),
    [loginError],
  )

  React.useEffect(() => {
    props.setLoginResponse?.(state)
  }, [props, state])

  return (
    <>
      <LoginLayoutDescription description={description} title={title} />
      <div className="flex w-full flex-col">
        {loginError ? FormError : null}
        <LoginProviderButtons providers={props.providers} />
        <Form {...form}>
          <form
            className="w-full space-y-3"
            action={(formData) => {
              const password = form.getValues('password')
              props.setPassword?.(password)

              form.reset(form.getValues())
              formAction(formData)
            }}
            id="login-form"
          >
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem className="w-full space-y-0.5">
                  <FormControl>
                    <Input
                      className="w-full"
                      inputSize="lg"
                      placeholder="Username"
                      type="text"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="w-full space-y-0.5">
                  <FormControl>
                    <Input
                      autoComplete="off"
                      className="w-full"
                      inputSize="lg"
                      placeholder="Password"
                      type="password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mb-8 mt-4 flex items-start">
              <Link
                className="focus-outline rounded-sm px-1 py-0 text-sm font-medium text-gray-500 underline hover:text-blue-700 focus:text-blue-700 dark:text-gray-400 dark:hover:text-blue-400 dark:focus:text-blue-400"
                href="/login/password/forgot"
              >
                Forgot your password?
              </Link>
            </div>
            <SubmitButton />
            <p aria-live="polite" className="sr-only" role="status">
              {state.message}
            </p>
          </form>
        </Form>
      </div>
    </>
  )
}

export { LoginForm }
