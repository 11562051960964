export function GoogleIcon(
  props: React.ComponentProps<'svg'>,
): React.JSX.Element {
  return (
    <svg
      className="h-6 w-6 flex-shrink-0"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M19.68 12.1818C19.68 11.6145 19.6291 11.0691 19.5345 10.5454H12V13.64H16.3055C16.12 14.64 15.5564 15.4873 14.7091 16.0545V18.0618H17.2945C18.8073 16.6691 19.68 14.6182 19.68 12.1818Z"
        fill="#4285F4"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.0007 20C14.1607 20 15.9716 19.2836 17.2952 18.0618L14.7097 16.0545C13.9934 16.5345 13.077 16.8182 12.0007 16.8182C9.91702 16.8182 8.15338 15.4109 7.52429 13.52H4.85156V15.5927C6.16793 18.2073 8.87338 20 12.0007 20Z"
        fill="#34A853"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.52364 13.52C7.36364 13.04 7.27273 12.5273 7.27273 12C7.27273 11.4727 7.36364 10.96 7.52364 10.48V8.40726H4.85091C4.30909 9.48726 4 10.7091 4 12C4 13.2909 4.30909 14.5127 4.85091 15.5927L7.52364 13.52Z"
        fill="#FBBC05"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.0007 7.18182C13.1752 7.18182 14.2297 7.58545 15.0588 8.37818L17.3534 6.08364C15.9679 4.79273 14.157 4 12.0007 4C8.87338 4 6.16793 5.79273 4.85156 8.40727L7.52429 10.48C8.15338 8.58909 9.91702 7.18182 12.0007 7.18182Z"
        fill="#EA4335"
        fillRule="evenodd"
      />
    </svg>
  )
}
