'use client'

import { Alert, AlertDescription } from '@viewpoint/ui'
import { MailWarningIcon } from 'lucide-react'
import Link from 'next/link'

export function UpdateErrorTimeout(): JSX.Element {
  return (
    <>
      <Alert variant="destructive">
        <MailWarningIcon className="h-4 w-4 !text-rose-600 dark:text-rose-400" />
        <AlertDescription>
          <span>That password update link is no longer valid. </span>
          <Link
            className="focus-outline rounded-sm py-0 font-medium text-gray-600 underline ring-blue-600 hover:text-blue-700 focus:text-blue-700 dark:text-gray-400 dark:hover:text-blue-400 dark:focus:text-blue-400"
            href="/login/password/forgot"
          >
            Click here
          </Link>
          <span> to resend the link.</span>
        </AlertDescription>
      </Alert>
      <div className="mb-8 mt-12 flex items-center justify-center gap-x-1 text-sm text-gray-500 sm:justify-start">
        <Link
          className="focus-outline rounded-sm py-0 font-medium text-gray-600 underline ring-blue-600 hover:text-blue-700 focus:text-blue-700 dark:text-gray-400 dark:hover:text-blue-400 dark:focus:text-blue-400"
          href="/login"
        >
          Back to login
        </Link>
      </div>
    </>
  )
}
