import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs))
}

export function isValidUrl(url: string): boolean {
  try {
    // eslint-disable-next-line no-new -- This is the correct way to check if a URL is valid
    new URL(url)
    return true
  } catch (err) {
    return false
  }
}

export function reorderByKeys<K, R>(
  keys: readonly K[],
  records: readonly R[],
  keyFn: (key: K, record: R) => boolean,
): R[] {
  return keys.map((k) => records.filter((r) => keyFn(k, r))[0])
}

export function objectMap(
  obj: Record<string, unknown>,
  fn: (arg0: unknown, arg1: string, arg2: number) => unknown,
): Record<string, unknown> {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]),
  )
}
