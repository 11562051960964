import Image from 'next/image'
import * as React from 'react'
// import * as RadixSeparator from '@radix-ui/react-separator'
import { LoginBackground } from '@/components/icons/login-background'
import { cn } from '@/lib/utils'
import { LoginThemeToggle } from './login-theme-toggle'

export function LoginLayoutTemplate(
  props: React.PropsWithChildren<{
    districtMessage?: string
    enableDistrictMessage?: boolean
    enableSamlSso?: boolean
  }>,
): React.ReactElement {
  return (
    <>
      <div className="fixed flex h-screen w-screen flex-col bg-gradient-to-r from-gray-900/10 to-gray-950/10 dark:from-gray-900 dark:to-gray-950">
        <LoginBackground />
      </div>
      <div className="relative h-full min-h-screen bg-transparent md:grid lg:grid-cols-[800px_1fr]">
        <div className="z-10 flex min-h-screen w-full flex-col-reverse justify-start bg-transparent sm:flex-col sm:justify-between sm:bg-white dark:sm:bg-gray-900">
          <div
            className={cn(
              'flex h-full flex-col justify-center gap-12 rounded-t-3xl bg-white p-12 dark:bg-gray-900',
              props.enableDistrictMessage &&
                props.districtMessage &&
                props.districtMessage !== ''
                ? 'sm:mb-8'
                : 'sm:mb-16',
            )}
          >
            <div className="flex items-end justify-start gap-x-0 sm:w-[500px] sm:max-w-[500px] sm:self-center">
              <span className="relative h-10 w-10 sm:h-10 sm:w-10">
                <Image
                  alt="Viewpoint Logo"
                  className=""
                  fill
                  priority
                  src="/viewpoint.svg"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </span>
              <span className="text-3xl font-semibold tracking-tighter text-gray-700 dark:text-gray-200">
                Viewpoint
              </span>
            </div>
            {props.enableDistrictMessage && props.districtMessage ? (
              <div className="flex w-full gap-2 self-center bg-white dark:bg-gray-900 sm:w-[500px] sm:max-w-[500px]">
                <div className="min-h-full w-1.5 min-w-[0.375rem] rounded bg-blue-500/80" />
                <div className="relative flex w-full flex-col items-start justify-end sm:justify-center">
                  <div className="flex flex-col gap-y-1.5 text-pretty py-1 text-base leading-tight text-gray-600 dark:text-gray-400">
                    {props.districtMessage.split('\n').map((text, index) => (
                      <div
                        className={text === '' ? 'h-[17.5px]' : ''}
                        // eslint-disable-next-line react/no-array-index-key -- We are not reordering or adding/removing items so index is fine
                        key={index}
                      >
                        {text}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="w-full self-center bg-white dark:bg-gray-900 sm:w-[500px] sm:max-w-[500px] sm:p-0">
              <div className="flex w-full flex-col items-start justify-end sm:justify-center">
                {props.children}
              </div>
            </div>
          </div>
          <div>
            <LoginThemeToggle />
          </div>
        </div>
      </div>
    </>
  )
}

export function LoginLayoutDescription({
  description,
  title,
}: {
  title: string
  description: string
}): React.ReactElement {
  return (
    <div className="flex flex-col gap-2">
      <span className="text-2xl font-semibold tracking-tighter sm:text-4xl">
        {title}
      </span>
      <span className="mb-8 ml-1 text-base leading-tight text-gray-500 dark:text-gray-400 sm:mb-12">
        {description}
      </span>
    </div>
  )
}
