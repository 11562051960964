import { z } from 'zod'

export const updateUserPasswordFormSchema = z
  .object({
    newPassword: z.string().min(1, { message: 'This field cannot be empty.' }),
    confirmNewPassword: z
      .string()
      .min(1, { message: 'This field cannot be empty.' }),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    path: ['confirmNewPassword'],
    message: "Passwords don't match",
  })
