import type { Dispatch, SetStateAction } from 'react'
import * as React from 'react'
import { signIn } from 'next-auth/react'
import { z } from 'zod'
import { useRouter, useSearchParams } from 'next/navigation'
import { AlertCircleIcon, CheckCircleIcon } from 'lucide-react'
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  LoadingIcon,
  Input,
  toast,
} from '@viewpoint/ui'
import type { InputProps } from '@viewpoint/ui'
// import logger from '@viewpoint/logger'
import { useFocus } from '@/hooks'
import type { ValidateOtpRequestData } from '@/api/validate-otp/route'
import { isValidUrl } from '@/lib/utils'
import { LoginLayoutDescription } from './login-layout-template'

const otpValidateRequestSchema = z.object({
  isOtpValid: z.boolean(),
})

async function sendOTPValidateRequest(
  data: ValidateOtpRequestData,
): Promise<boolean> {
  const response = await fetch('/api/validate-otp', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  })

  if (!response.ok) {
    return false
  }

  const initialData = await response.json()

  const responseData = otpValidateRequestSchema.parse(initialData)

  return Boolean(responseData.isOtpValid)
}

async function onAllFilled(
  email: string,
  isLdap: boolean,
  otpCode: string[],
  username: string,
  password: string,
  setIsVerifying: Dispatch<SetStateAction<boolean>>,
  router: ReturnType<typeof useRouter>,
  setVerificationError: Dispatch<
    SetStateAction<
      | {
          title?: string | undefined
          description?: string | undefined
        }
      | undefined
    >
  >,
  isViewpointLoginRedirectEnabled: boolean,
  callbackUrl?: string | null,
): Promise<void> {
  setVerificationError(undefined)
  setIsVerifying(true)
  const { id, dismiss, update } = toast({
    description: (
      <div className="flex items-center gap-2">
        <LoadingIcon size="sm" />
        <span>Verifying...</span>
      </div>
    ),
  })

  const isOtpValid = await sendOTPValidateRequest({
    email,
    code: otpCode.join(''),
  })

  if (isOtpValid) {
    //log user in and redirect
    const signInResponse = isLdap
      ? await signIn<'credentials'>('ldap', {
          username,
          password,
          redirect: false,
          callbackUrl: callbackUrl?.toString() ?? '/',
        })
      : await signIn<'credentials'>('credentials', {
          username,
          password,
          redirect: false,
          callbackUrl: callbackUrl?.toString() ?? '/',
        })

    if (!signInResponse) {
      router.push('/login')
      //todo: push to login and set login error as query string param
      // setLoginError({
      //   title: 'Something went wrong',
      //   description:
      //     'There was an issue trying to sign you in. Please try again or contact your system administrator if the issue persists.',
      // })
      return
    }

    if (signInResponse.error ?? !signInResponse.ok) {
      router.push('/login')
      //todo: push to login and set login error as query string param
      // setLoginError({
      //   title: 'Something went wrong',
      //   description: 'Incorrect username or password',
      // })
      // logger.error({
      //   callingFunction: 'onAllFilled',
      //   filePath: 'apps/web/app/login/components/otp.tsx',
      //   error: signInResponse.error,
      //   message: 'Sign in failed',
      // })
      return
    }

    // sign in successful, redirect user
    update({
      id,
      description: (
        <div className="flex items-center gap-2">
          <CheckCircleIcon className="h-3.5 w-3.5 text-emerald-600" />
          <span>Verified</span>
        </div>
      ),
    })

    // sign in successful, redirect user
    if (signInResponse.url && isValidUrl(signInResponse.url)) {
      const url = new URL(signInResponse.url)
      router.push(`${url.pathname}?${url.searchParams.toString()}`)
      return
    }

    if (callbackUrl) {
      router.push(callbackUrl.toString())
      return
    }

    router.push('/')

    dismiss()
  } else {
    setVerificationError({
      title: 'Invalid Code',
      description:
        'It looks like the code you entered is invalid. Please check the code, or resend the verification email to receive a new valid code. Remember that each code is only valid for 10 minutes',
    })
    // logger.debug({
    //   callingFunction: 'onAllFilled',
    //   filePath: 'apps/web/app/login/components/otp.tsx',
    //   message: 'Invalid MFA code',
    // })
  }

  setIsVerifying(false)
}

//todo: make this work with LDAP
export function LoginVerification({
  email,
  isLdap,
  isViewpointRedirectEnabled,
  username,
  password,
}: {
  email: string
  isLdap: boolean
  isViewpointRedirectEnabled: boolean
  username: string
  password: string
}): JSX.Element {
  const [verificationError, setVerificationError] = React.useState<{
    title?: string
    description?: string
  }>()
  const router = useRouter()
  const searchParams = useSearchParams()
  const callbackUrl = searchParams.get('callbackUrl')

  const [isVerifying, setIsVerifying] = React.useState(false)
  const partialEmail = React.useMemo(
    () =>
      email.replace(
        /(?<partialEmail>\w{3})[\w.-]+@(?<domain>[\w.]+\w)/,
        '$1***@$2',
      ),
    [email],
  )

  const [code1Ref, setCode1Focus] = useFocus<HTMLInputElement>()
  const [code2Ref, setCode2Focus] = useFocus<HTMLInputElement>()
  const [code3Ref, setCode3Focus] = useFocus<HTMLInputElement>()
  const [code4Ref, setCode4Focus] = useFocus<HTMLInputElement>()
  const [code5Ref, setCode5Focus] = useFocus<HTMLInputElement>()
  const [code6Ref, setCode6Focus] = useFocus<HTMLInputElement>()

  const [otpCode, setOtpCode] = React.useState<string[]>([
    '',
    '',
    '',
    '',
    '',
    '',
  ])
  const [currentIndex, setCurrentIndex] = React.useState(0)

  const getFocusFn = React.useCallback(
    (index: number) => {
      return {
        0: setCode1Focus,
        1: setCode2Focus,
        2: setCode3Focus,
        3: setCode4Focus,
        4: setCode5Focus,
        5: setCode6Focus,
      }[index]
    },
    [
      setCode1Focus,
      setCode2Focus,
      setCode3Focus,
      setCode4Focus,
      setCode5Focus,
      setCode6Focus,
    ],
  )

  const getUpdatedOtpCode = React.useCallback(
    (
      code: string[],
      codePart: string,
      index: number,
      options?: {
        updateIndex?: boolean
      },
    ) => {
      const updatedOtpCode = code.map((part, idx) =>
        idx === index ? codePart : part,
      )

      const newIndex =
        currentIndex > 0 && codePart === ''
          ? currentIndex - 1
          : currentIndex + 1

      return {
        updatedOtpCode,
        updatedIndex: options?.updateIndex ? newIndex : currentIndex,
      }
    },
    [currentIndex],
  )

  const otpCodeReplace = React.useCallback(
    (
      codePart: string,
      index: number,
      options?: {
        updateIndex?: boolean
      },
    ) => {
      const { updatedOtpCode, updatedIndex } = getUpdatedOtpCode(
        otpCode,
        codePart,
        index,
        options,
      )

      const setFocus = getFocusFn(updatedIndex)

      options?.updateIndex === true && setCurrentIndex(updatedIndex)
      options?.updateIndex === true && setFocus && setFocus(true)

      setOtpCode(updatedOtpCode)
    },
    [getFocusFn, getUpdatedOtpCode, otpCode],
  )

  const inputKeyDown = React.useCallback(
    (key: string) => {
      const prevFocusFn =
        currentIndex > 0 ? getFocusFn(currentIndex - 1) : getFocusFn(0)

      if (
        key.toLocaleLowerCase() === 'backspace' &&
        otpCode[currentIndex] === '' &&
        currentIndex > 0
      ) {
        otpCodeReplace('', currentIndex - 1)
        setCurrentIndex(currentIndex - 1)
        prevFocusFn && prevFocusFn(true)
      }
    },
    [currentIndex, getFocusFn, otpCode, otpCodeReplace],
  )

  const inputOnPaste = React.useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault()
      const content = event.clipboardData.getData('Text')
      const contentArray = content.split('')
      let modifiedOtpCode = otpCode
      let modifiedIndex = currentIndex

      contentArray.every((char) => {
        if (modifiedIndex === 6) {
          return false
        }

        if (
          !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(char)
        ) {
          return false
        }
        const { updatedOtpCode } = getUpdatedOtpCode(
          modifiedOtpCode,
          char,
          modifiedIndex,
          { updateIndex: false },
        )
        modifiedIndex += 1

        modifiedOtpCode = updatedOtpCode

        return true
      })

      setOtpCode(modifiedOtpCode)

      const focusFn = getFocusFn(modifiedIndex)
      focusFn && focusFn(true)

      if (modifiedOtpCode.findIndex((digit) => digit === '') === -1) {
        void onAllFilled(
          email,
          isLdap,
          modifiedOtpCode,
          username,
          password,
          setIsVerifying,
          router,
          setVerificationError,
          isViewpointRedirectEnabled,
          callbackUrl,
        )
      }
    },
    [
      callbackUrl,
      currentIndex,
      email,
      getFocusFn,
      getUpdatedOtpCode,
      isLdap,
      isViewpointRedirectEnabled,
      otpCode,
      password,
      router,
      username,
    ],
  )

  const inputOnChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      const digit = e.target.value

      const nextFocusFn =
        currentIndex < 5 ? getFocusFn(currentIndex + 1) : getFocusFn(5)

      const updatedOtpCode =
        digit.toLocaleLowerCase() !== '' &&
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(digit)
          ? otpCode.map((mapDigit, index) =>
              index === currentIndex ? digit : mapDigit,
            )
          : otpCode
      const areAllFilled =
        digit.toLocaleLowerCase() !== '' &&
        updatedOtpCode.findIndex((updatedDigit) => updatedDigit === '') === -1

      if (areAllFilled) {
        otpCodeReplace(digit, currentIndex, { updateIndex: false })
        void onAllFilled(
          email,
          isLdap,
          updatedOtpCode,
          username,
          password,
          setIsVerifying,
          router,
          setVerificationError,
          isViewpointRedirectEnabled,
          callbackUrl,
        )
      }

      if (digit.toLocaleLowerCase() === '' && otpCode[currentIndex] !== '') {
        otpCodeReplace('', currentIndex, { updateIndex: false })
        return
      }

      if (
        ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(digit) &&
        digit.toLocaleLowerCase() !== ''
      ) {
        otpCodeReplace(digit, currentIndex)
        currentIndex < 5 && setCurrentIndex(currentIndex + 1)
        nextFocusFn && nextFocusFn(true)
      }
    },
    [
      callbackUrl,
      currentIndex,
      email,
      getFocusFn,
      isLdap,
      isViewpointRedirectEnabled,
      otpCode,
      otpCodeReplace,
      password,
      router,
      username,
    ],
  )

  const FormError = (
    <Alert className="mb-4" variant="destructive">
      <AlertCircleIcon className="h-4 w-4" />
      {verificationError?.title ? (
        <AlertTitle>{verificationError.title}</AlertTitle>
      ) : null}
      {verificationError?.description ? (
        <AlertDescription>{verificationError.description}</AlertDescription>
      ) : null}
    </Alert>
  )

  const inputProps = React.useMemo<Omit<InputProps, 'ref'>>(
    () => ({
      autoCorrect: 'off',
      autoCapitalize: 'off',
      autoComplete: 'off',
      className:
        'bg-transparent border rounded-lg p-1 border-gray-200 outline-none text-3xl font-semibold w-11 text-center h-11',
      disabled: isVerifying,
      inputMode: 'text',
      maxLength: 1,
      onChange: inputOnChange,
      onKeyDown: (e) => {
        inputKeyDown(e.key)
      },
      onPaste: inputOnPaste,
      pattern: 'd{1}',
      required: true,
      type: 'tel',
    }),
    [inputKeyDown, inputOnChange, inputOnPaste, isVerifying],
  )

  return (
    <>
      <LoginLayoutDescription
        description={`Please enter the code sent to ${partialEmail}.`}
        title="Let's make sure it's you"
      />
      <div className="flex w-full flex-col gap-10">
        {verificationError ? FormError : null}
        <div className="flex w-full gap-2">
          <Input
            {...{
              ...inputProps,
              'aria-label':
                'Enter verification code. On entering code in the input fields, the focus will automatically move on to the next input fields. After entering the verification code, the page gets updated automatically. Digit 1',
              autoFocus: true,
              onFocus: () => {
                setCurrentIndex(0)
              },
              ref: code1Ref,
              value: otpCode[0],
            }}
          />
          <Input
            {...{
              ...inputProps,
              'aria-label': 'Digit 2',
              onFocus: () => {
                setCurrentIndex(1)
              },
              ref: code2Ref,
              value: otpCode[1],
            }}
          />
          <Input
            {...{
              ...inputProps,
              'aria-label': 'Digit 3',
              onFocus: () => {
                setCurrentIndex(2)
              },
              ref: code3Ref,
              value: otpCode[2],
            }}
          />
          <Input
            {...{
              ...inputProps,
              'aria-label': 'Digit 4',
              onFocus: () => {
                setCurrentIndex(3)
              },
              ref: code4Ref,
              value: otpCode[3],
            }}
          />
          <Input
            {...{
              ...inputProps,
              'aria-label': 'Digit 5',
              onFocus: () => {
                setCurrentIndex(4)
              },
              ref: code5Ref,
              value: otpCode[4],
            }}
          />
          <Input
            {...{
              ...inputProps,
              'aria-label': 'Digit 6',
              onFocus: () => {
                setCurrentIndex(5)
              },
              ref: code6Ref,
              value: otpCode[5],
            }}
          />
        </div>
        <div>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {`Didn't receive a code?`}
          </span>
          <Button size="xs" variant="link">
            Resend
          </Button>
        </div>
      </div>
    </>
  )
}
