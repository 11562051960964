export function LoginBackground(): React.JSX.Element {
  return (
    <svg
      className="opacity-10"
      height="100%"
      id="patternId"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          height="70"
          id="a"
          patternTransform="scale(3) rotate(5)"
          patternUnits="userSpaceOnUse"
          width="70"
        >
          <rect
            fill="hsla(220, 13%, 91%, 0)"
            height="100%"
            width="100%"
            x="0"
            y="0"
          />
          <path
            d="M-4.8 4.44L4 16.59 16.14 7.8M32 30.54l-13.23 7.07 7.06 13.23M-9 38.04l-3.81 14.5 14.5 3.81M65.22 4.44L74 16.59 86.15 7.8M61 38.04l-3.81 14.5 14.5 3.81"
            fill="none"
            stroke="hsla(216, 100%, 20%, 1)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M59.71 62.88v3h3M4.84 25.54L2.87 27.8l2.26 1.97m7.65 16.4l-2.21-2.03-2.03 2.21m29.26 7.13l.56 2.95 2.95-.55"
            fill="none"
            stroke="hsla(216, 100%, 36%, 1)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M58.98 27.57l-2.35-10.74-10.75 2.36M31.98-4.87l2.74 10.65 10.65-2.73M31.98 65.13l2.74 10.66 10.65-2.74"
            fill="none"
            stroke="hsla(216, 100%, 68%, 1)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            d="M8.42 62.57l6.4 2.82 2.82-6.41m33.13-15.24l-4.86-5.03-5.03 4.86m-14-19.64l4.84-5.06-5.06-4.84"
            fill="none"
            stroke="hsla(216, 100%, 36%, 1)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </pattern>
      </defs>
      <rect
        fill="url(#a)"
        height="800%"
        transform="translate(-15,-54)"
        width="800%"
      />
    </svg>
  )
}
